import React, { useEffect } from "react"

import { graphql, PageProps } from "gatsby"
import Layout from "../../components/Layouts/mainPage"
import { IndexPageData } from "../../interfaces/pageProps"

import { getIndexPageData } from "../../helpers/getIndexPageData"

import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner } from "../../components/Banners/Pkw"
import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { FormPKW } from "../../components/FormPKW"
import { NewCalculator } from "../../components/NewCalculator/NewCalculator"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { Partners } from "../../components/PartnersNew"
import { NewFooter } from "../../components/NewFooter"
import { SliderUsePromo } from "../../components/SliderUsePromo"
import { defaultListItems } from "../../components/SliderUsePromo/helpers"
import { SwitcherTextBlock } from "../../components/SwitcherTextBlock"
import { useHybridBanner } from "../../hooks/useHybridBanner"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const countDownTime = 1800000
const bannerDescription = `
за покупки в магазинах-партнерах
`
const subTitlePartners = (
  <>
    С 1 апреля по 19 мая получите <br /> увеличенную рассрочку на 18 месяцев на покупки в
    Магазинах-партнёрах акции.
  </>
)

export default function Page({ data }: PageProps<IndexPageData>) {
  const { ligalOrder, noIndex } = getIndexPageData(data)

  const bannerTitle = `Вернем <br />
  1 000 бонусов
  `
  const banner = useHybridBanner(
    Banner as any,
    { title: bannerTitle, desc: bannerDescription },
    { orderNum: "1", variantStyle: "vkEducation" },
    { orderNum: "1", isDynamicText: true },
    data.allDynamicBanner
  )

  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader redLogoWithSovcombank showHint={false} hasCTA countDownTime={countDownTime} />
      {banner}
      <FourAdvantagesPoints
        withTitle
        indent
        variant="pkwRedesign"
        title="Преимущества с Халвой:"
        orderNum="2"
      />
      <HowInstallmentWorks
        headTitle="Как получить выгоду с Халвой"
        variant="vkEducation"
        orderNum="3"
      />
      <SliderUsePromo
        orderNum="4"
        textList={defaultListItems}
        title={<>Как применить промокод</>}
      />
      <FormPKW
        dataLayerName="longPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        longForm
        countDownTime={countDownTime}
        withTimer={false}
        formBtnText="Оформить карту"
        orderNum="5"
        onlyPKWPage
        isGreenApprove
        emptyForm={false}
      />
      <NewCalculator additionalEventInDataLayer orderNum="6" />
      <Partners
        title="Более 250&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="7"
        variantStyle="vkEducation"
      />
      <SwitcherTextBlock mode="bezProcentov" seoBlockText="" indent orderNum="8" />
      <NewFooter ligal={ligalOrder} orderNum="9" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      order: page(url: "https://halvacard.ru/order/pkw/vk-education/") {
        phones
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
